// PostSurvey.js
import { Button, Typography, styled } from "@mui/material";
import { useMediaQuery } from "@mui/material";
const StyledContainer = styled("div")({
  textAlign: "center",
  marginTop: "30px",
});

const ButtonContainer = styled("div")({
  display: "flex",
  gap: 30,
  alignItems: "center",
  justifyContent: "center",
  marginTop: 20,
});

const primaryColor = "#B7BCA1";

const PostSurveyView = ({ onComplete, onCancel }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <StyledContainer>
      <Typography
        variant="h4"
        sx={{ marginBottom: isSmallScreen ? 3 : 4, fontFamily: "'magnat'" }}
      >
        Scan your face
      </Typography>
      <Typography
        variant="h4"
        sx={{ marginBottom: isSmallScreen ? 1 : 2, fontFamily: "'magnat'" }}
      >
        01.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: isSmallScreen ? 1 : 2, fontFamily: "'magnat'" }}
      >
        Pull hair back and remove glasses or anything that hides your skin.
      </Typography>
      <Typography
        variant="h4"
        sx={{ marginBottom: isSmallScreen ? 1 : 2, fontFamily: "'magnat'" }}
      >
        02.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: isSmallScreen ? 1 : 2, fontFamily: "'magnat'" }}
      >
        Try to use the same well-lit area for every scan.
      </Typography>
      <Typography
        variant="h4"
        sx={{ marginBottom: isSmallScreen ? 1 : 2, fontFamily: "'magnat'" }}
      >
        03.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: isSmallScreen ? 1 : 2, fontFamily: "'magnat'" }}
      >
        Relax your face. No smiling.
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: 1, fontFamily: "'magnat'" }}>
        04.
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginBottom: 1, fontFamily: "'magnat'" }}
      >
        For best results have a clean skin without any makeup or products
      </Typography>
      <ButtonContainer>
        <Button
          variant="outlined"
          sx={{
            marginTop: isSmallScreen ? 1 : 2,
            minWidth: "160px",
            border: `1px solid ${primaryColor}`,
            borderRadius: "20px",
            color: "black",
            fontWeight: "bold",
            height: 45,
            "&:hover": {
              border: `1px solid ${primaryColor}`,
            },
          }}
          onClick={onCancel}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onComplete}
          sx={{
            marginTop: isSmallScreen ? 1 : 2,
            minWidth: "160px",
            border: "1px solid grey",
            borderRadius: "20px",
            backgroundColor: primaryColor,
            "&:hover": {
              backgroundColor: primaryColor,
            },
            color: "white",
            "&.Mui-disabled": {
              background: "#b4b8a3",
              color: "#9E9595",
            },
            border: "none",
            fontFamily: "'magnat'",
          }}
        >
          Let's go
        </Button>
      </ButtonContainer>
    </StyledContainer>
  );
};

export default PostSurveyView;
