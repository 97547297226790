import { useRef, useState, useCallback, useEffect, useContext } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { Typography, useMediaQuery } from "@mui/material";
import SkinStats from "../SkinStats";
import ScanAnimation from "../ScanAnimation";
import useSkinAnalysis from "../../utils/hooks/useSkinAnalysis";
import { detectFace } from "../../utils/hooks/faceDetectionUtils";
import { calculateAverageBrightness } from "../../utils/hooks/calculateAverageBrightness";
import { freeDrawCoords } from "../../utils/freedrawCoords";

import { drawRedness } from "../../utils/drawingHelpers/drawRedness";
import { drawLines } from "../../utils/drawingHelpers/drawLines";
import { useTimer } from "react-timer-hook";
import ProductList from "../Products/ProductsList/index";
import { mapProducts } from "../../utils/productsMapper/mapProducts";
import TotalScore from "../TotalScore";
import CameraLayout from "./CameraLayout";
import WebcamCapture from "./Webcam";
import EmailForm from "../Mailer";
import Footer from "../Footer";
import ModelLoadingContext from "../../contexts/ModelLoadingContext";

const topScanner = require("../../assets/topScanner.png");
const bottomScanner = require("../../assets/bottomScanner.png");

const CameraComponent = (props: any) => {
  const { isModelLoaded } = useContext(ModelLoadingContext);
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [isFaceInShape, setIsFaceInShape] = useState<"good" | "ok" | "bad">(
    "bad"
  );
  const [isStraight, setIsStraight] = useState<"good" | "ok" | "bad">("bad");
  const [isLightGood, setIsLightGood] = useState<"good" | "ok" | "bad">("bad");
  const [chinLineY, setChinLineY] = useState(0);
  const [foreheadLineY, setForeheadLineY] = useState(0);
  const [showLayout, setShowLayout] = useState(false);
  const [countdownActive, setCountdownActive] = useState(false);
  const [showFirmness, setShowFirmness] = useState(true);
  const [showTexture, setShowTexture] = useState(true);
  const [showRedness, setShowRedness] = useState(true);
  const [showWrinkles, setShowWrinkles] = useState(true);
  const [showOilPores, setShowOilPores] = useState(true);
  const [showDarkSpots, setShowDarkSpots] = useState(true);
  const [topPercent, setTopPercent] = useState<number>();
  const [botPercent, setBottomPercent] = useState<number>();

  const answers = localStorage.getItem("prompt");
  const {
    apiResult,
    loading,
    analyzeSkin,
    featuresCoords,
    requestReady,
    wrinkles,
    productIds,
  } = useSkinAnalysis();
  const { seconds, start, restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + 3000),
    autoStart: false,
    onExpire: () => {
      capture();
    },
  });
  const [allPoints, setAllPoints] = useState<any>(null);
  const detectFaceIntervalRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const capture = useCallback(async () => {
    if (webcamRef.current) {
      try {
        clearInterval(detectFaceIntervalRef.current);

        const imageSrc = await webcamRef.current.getScreenshot();

        setUrl(imageSrc);
        setIsCameraVisible(false);
        setCountdownActive(false);

        if (imageSrc) {
          const image = new Image();
          image.src = imageSrc;

          const detections = await faceapi
            .detectSingleFace(image, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks();

          if (detections && canvasRef.current) {
            const { landmarks } = detections;
            const mouthMiddle = {
              x: (landmarks.getMouth()[0].x + landmarks.getMouth()[6].x) / 2,
              y: (landmarks.getMouth()[0].y + landmarks.getMouth()[6].y) / 2,
            };

            const noseMiddle = {
              x: landmarks.getNose()[0].x,
              y: landmarks.getNose()[4].y,
            };

            const leftEyebrowMiddle = {
              x:
                (landmarks.getLeftEyeBrow()[0].x +
                  landmarks.getLeftEyeBrow()[4].x) /
                2,
              y:
                (landmarks.getLeftEyeBrow()[0].y +
                  landmarks.getLeftEyeBrow()[4].y) /
                2,
            };
            const rightEyebrowMiddle = {
              x:
                (landmarks.getRightEyeBrow()[0].x +
                  landmarks.getRightEyeBrow()[4].x) /
                2,
              y:
                (landmarks.getRightEyeBrow()[0].y +
                  landmarks.getRightEyeBrow()[4].y) /
                2,
            };

            const leftEyeMiddle = {
              x:
                (landmarks.getLeftEye()[0].x + landmarks.getLeftEye()[3].x) / 2,
              y:
                (landmarks.getLeftEye()[0].y + landmarks.getLeftEye()[3].y) / 2,
            };
            const rightEyeMiddle = {
              x:
                (landmarks.getRightEye()[0].x + landmarks.getRightEye()[3].x) /
                2,
              y:
                (landmarks.getRightEye()[0].y + landmarks.getRightEye()[3].y) /
                2,
            };

            const coordinates = JSON.stringify({
              mouthMiddle,
              noseMiddle,
              leftEyebrowMiddle,
              rightEyebrowMiddle,
              leftEyeMiddle,
              rightEyeMiddle,
            });
            analyzeSkin(imageSrc, coordinates, props.products, answers);

            const allPoints: any = {
              mouth: landmarks.getMouth(),
              nose: landmarks.getNose(),
              leftEyeBrow: landmarks.getLeftEyeBrow(),
              rightEyeBrow: landmarks.getRightEyeBrow(),
              leftEye: landmarks.getLeftEye(),
              rightEye: landmarks.getRightEye(),
              jaw: landmarks.getJawOutline(),
            };

            setAllPoints(allPoints);
            const box = detections.detection.box;
            setChinLineY(box.y + box.height);
            setForeheadLineY(box.y);
          }
        }
      } catch (error) {
        console.error("Error capturing image:", error);
      }
    }
  }, [webcamRef, setUrl, setIsFaceInShape, setIsCameraVisible]);

  useEffect(() => {
    if (allPoints && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const image1 = new Image();
      image1.src = topScanner;

      const image2 = new Image();
      image2.src = bottomScanner;

      if (context) {
        const mouthMiddle = {
          x: (allPoints.mouth[0].x + allPoints.mouth[6].x) / 2,
          y: (allPoints.mouth[0].y + allPoints.mouth[6].y) / 2,
        };
        const image1 = new Image();
        image1.src = topScanner;
        image1.onload = () => {
          context?.drawImage(
            image1,
            mouthMiddle.x - 125,
            allPoints.rightEyeBrow[3].y +
              mouthMiddle.y -
              allPoints.jaw[8].y -
              40,
            250,
            80
          );
        };
        const expressionValue =
          allPoints.rightEyeBrow[3].y + mouthMiddle.y - allPoints.jaw[8].y - 40;

        const percentageTop = (expressionValue / 660) * 100;
        setTopPercent(percentageTop + 10);
        const image2 = new Image();
        image2.src = bottomScanner;
        image2.onload = () => {
          context?.drawImage(
            image2,
            allPoints.jaw[8].x - 125,
            allPoints.jaw[8].y - 60,
            250,
            80
          );
        };
        const expression = allPoints.jaw[8].y - 60;
        const percantageEnd = (expression / 660) * 100;
        setBottomPercent(percantageEnd);
      }
    }
  }, [allPoints]);

  useEffect(() => {
    if (requestReady && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context?.clearRect(0, 0, canvas.width, canvas.height);

      if (context) {
        if (showRedness) {
          drawRedness(
            context,
            freeDrawCoords(allPoints, "redness"),
            "red",
            0.1,
            featuresCoords
          );
        }
        if (showFirmness) {
          drawLines(context, freeDrawCoords(allPoints, "firmness"), "pink");
        }
        if (showTexture) {
          drawLines(context, freeDrawCoords(allPoints, "texture"), "#CD47F1");
        }
        if (showWrinkles) {
          wrinkles.forEach((wrinkle: any) => {
            if (wrinkle === "UnderRightEye") {
              drawLines(
                context,
                freeDrawCoords(allPoints, "rightWrinkle"),
                "green"
              );
            }
            if (wrinkle === "UnderLeftEye") {
              drawLines(
                context,
                freeDrawCoords(allPoints, "leftWrinkle"),
                "green"
              );
            }
          });
        }
      }
    }
  }, [requestReady, showRedness, showFirmness, showTexture, showWrinkles]);

  useEffect(() => {
    if (!isCameraVisible || !webcamRef) {
      clearInterval(detectFaceIntervalRef.current);
      return;
    }

    detectFaceIntervalRef.current = setInterval(
      () =>
        detectFace(
          isModelLoaded,
          webcamRef,
          setIsStraight,
          setIsFaceInShape,
          setIsLightGood,
          calculateAverageBrightness
        ),
      200
    );

    return () => {
      clearInterval(detectFaceIntervalRef.current);
    };
  }, [isCameraVisible, webcamRef]);

  useEffect(() => {
    if (
      isLightGood !== "bad" &&
      isStraight !== "bad" &&
      isFaceInShape !== "bad"
    ) {
      setCountdownActive(true);
      start();
    } else {
      setCountdownActive(false);
      restart(new Date(Date.now() + 3000), false);
    }
  }, [isLightGood, isStraight, isFaceInShape]);

  const handleUserMedia = () => {
    setShowLayout(true);
  };

  const truncateDescription = (description: string) => {
    const maxLength = 100;
    if (description.length <= maxLength) {
      return description;
    } else {
      return description.substring(0, maxLength - 3) + "...";
    }
  };

  const stringifyAndNumberize = (price: any) => {
    const stringifiedPrice = price.toString();

    return stringifiedPrice.length === 2 ||
      stringifiedPrice.length === 3 ||
      stringifiedPrice.length === 1
      ? stringifiedPrice + ".00"
      : stringifiedPrice;
  };

  useEffect(() => {
    setIsCameraVisible(true);
  }, []);

  return (
    <>
      <>
        <WebcamCapture
          webcamRef={webcamRef}
          isCameraVisible={isCameraVisible}
          handleUserMedia={handleUserMedia}
        />

        <CameraLayout
          countdownActive={countdownActive}
          seconds={seconds}
          isCameraVisible={isCameraVisible}
          isFaceInShape={isFaceInShape}
          isLightGood={isLightGood}
          isStraight={isStraight}
        />
      </>
      {url && (
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <img src={url} alt="Screenshot" />
          <canvas
            ref={canvasRef}
            style={{ position: "absolute" }}
            width={370}
            height={660}
          />

          {requestReady ? (
            <>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  height: 100,
                  padding: "10px 0px 10px 0px",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  width: 370,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "'magnat'",
                  }}
                >
                  Tap scores to see your perfect routine
                </Typography>
                <SkinStats
                  skinAnalysisResult={apiResult}
                  loading={loading}
                  controlFirmness={setShowFirmness}
                  controlTexture={setShowTexture}
                  controlRedness={setShowRedness}
                  controlWrinkles={setShowWrinkles}
                  controlOilPores={setShowOilPores}
                  controlDarkSpots={setShowDarkSpots}
                />
              </div>
            </>
          ) : url ? (
            <ScanAnimation
              url={url}
              foreheadLineY={foreheadLineY + 200}
              chinLineY={chinLineY + 400}
              scanTopPosition={topPercent}
              scanMidPosition={botPercent}
              scanBottomPosition={topPercent}
            />
          ) : null}
        </div>
      )}
      {requestReady ? (
        <>
          <TotalScore scores={apiResult} />
          <ProductList
            products={mapProducts(
              props.products,
              answers,
              apiResult,
              productIds
            )}
          />
          <EmailForm
            qa={answers}
            templateParams={{
              total: Math.ceil(
                (Number(apiResult[0].score) +
                  Number(apiResult[1].score) +
                  Number(apiResult[2].score) +
                  Number(apiResult[3].score) +
                  Number(apiResult[4].score) +
                  Number(apiResult[5].score)) /
                  6
              ),
              wrinkles: apiResult[0].score,
              firmness: apiResult[1].score,
              redness: apiResult[2].score,
              darkSpots: apiResult[3].score,
              oilPores: apiResult[4].score,
              texture: apiResult[5].score,
              email_to: "krilmi220601@gmail.com",
              product1Image: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[0].product.image_link,
              product1Href: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[0].product.link,
              product1Name: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[0].product.title,
              product1Description: truncateDescription(
                mapProducts(props.products, answers, apiResult, productIds)[0]
                  .product.description
              ),
              product1Price: stringifyAndNumberize(
                mapProducts(props.products, answers, apiResult, productIds)[0]
                  .product.price
              ),

              product2Image: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[1].product.image_link,
              product2Href: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[1].product.link,
              product2Name: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[1].product.title,
              product2Description: truncateDescription(
                mapProducts(props.products, answers, apiResult, productIds)[1]
                  .product.description
              ),
              product2Price: stringifyAndNumberize(
                mapProducts(props.products, answers, apiResult, productIds)[1]
                  .product.price
              ),

              product3Image: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[2].product.image_link,
              product3Href: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[2].product.link,
              product3Name: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[2].product.title,
              product3Description: truncateDescription(
                mapProducts(props.products, answers, apiResult, productIds)[2]
                  .product.description
              ),
              product3Price: stringifyAndNumberize(
                mapProducts(props.products, answers, apiResult, productIds)[2]
                  .product.price
              ),

              product4Image: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[3].product.image_link,
              product4Href: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[3].product.link,
              product4Name: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[3].product.title,
              product4Description: truncateDescription(
                mapProducts(props.products, answers, apiResult, productIds)[3]
                  .product.description
              ),
              product4Price: stringifyAndNumberize(
                mapProducts(props.products, answers, apiResult, productIds)[3]
                  .product.price
              ),

              product5Image: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[4].product.image_link,
              product5Href: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[4].product.link,
              product5Name: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[4].product.title,
              product5Description: truncateDescription(
                mapProducts(props.products, answers, apiResult, productIds)[4]
                  .product.description
              ),
              product5Price: stringifyAndNumberize(
                mapProducts(props.products, answers, apiResult, productIds)[4]
                  .product.price
              ),

              product6Image: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[5].product.image_link,
              product6Href: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[5].product.link,
              product6Name: mapProducts(
                props.products,
                answers,
                apiResult,
                productIds
              )[5].product.title,
              product6Description: truncateDescription(
                mapProducts(props.products, answers, apiResult, productIds)[5]
                  .product.description
              ),
              product6Price: stringifyAndNumberize(
                mapProducts(props.products, answers, apiResult, productIds)[5]
                  .product.price
              ),
            }}
          />
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default CameraComponent;
