export const mapProducts = (
  products: any,
  answers: any,
  scores: any,
  productIds: any
) => {
  const getProductById = (productId: number) => {
    return products.find((product: any) => product.id === productId);
  };
  let product1;

  const wrinkles = scores[0].score;
  const firmness = scores[1].score;
  const darkSpots = scores[3].score;
  const oiliness = scores[4].score;

  if (Number(oiliness) < 65) {
    product1 = getProductById(80);
  } else {
    product1 = getProductById(73);
  }

  let product2 = getProductById(11);

  const product3 = getProductById(productIds[0]);
  const product4 = getProductById(productIds[1]);
  const product5 = getProductById(productIds[2]);
  const product6 = getProductById(productIds[3]);

  return [
    { category: "Cleanse", product: product1 },
    { category: "Serum", product: product2 },
    { category: "Day moisturiser", product: product3 },
    { category: "Night moisturiser", product: product4 },
    { category: "Eye", product: product5 },
    { category: "Prevent", product: product6 },
  ];
};
