import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Container,
  RadioGroup,
  FormControl,
  FormControlLabel,
  styled,
  TextField,
  useMediaQuery,
} from "@mui/material";
import PostSurveyView from "./PostSurvey";

const primaryColor = "#B7BCA1";

const StyledContainer = styled(Container)({
  padding: 0,
  textAlign: "center",
  width: "325px",
  transition: "transform 0.5s ease",
});

const QuestionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 0,
});

const OptionButton = styled(Button)(({ theme, selected }) => ({
  marginTop: 10,
  marginBottom: 5,
  width: "350px",
  height: 45,
  padding: theme.spacing(2),
  fontFamily: "'magnat'",
  textAlign: "center",
  borderRadius: 20,
  border: `1px solid ${primaryColor}`,
  backgroundColor: selected ? primaryColor : "inherit",
  color: selected ? "white" : "black",
  "&:hover": {
    backgroundColor: selected ? primaryColor : theme.palette.action.hover,
  },
  marginLeft: "27px",
  textTransform: "none", // Add this line to override text-transform
}));

const ButtonsContainer = styled("div")({
  marginTop: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
});

const NextButton = styled(Button)({
  width: "165px",
  height: 45,
  margin: "8px",
  borderRadius: 20,
  color: "white",
  border: `1px solid ${primaryColor}`,
  fontFamily: "'magnat'",
});

const Survey = ({ onComplete }) => {
  const [showPostSurveyView, setShowPostSurveyView] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("prompt")) {
      localStorage.removeItem("prompt");
      console.log("clearing prompot");
    }
    if (localStorage.getItem("email")) {
      localStorage.removeItem("email");
      console.log("clearing email");
    }
  }, []);

  const initialFormData = {
    steps: [
      {
        question:
          "Welcome to flânerie.<br/>Let’s find your essentials.<br/>But first tell us...",
        options: [
          {
            label: "I am a flânerie enthusiast",
            value: "I am a flânerie enthusiast",
          },
          {
            label: "I am new here",
            value: "I am new here",
          },
        ],
      },
      {
        question:
          "Seeking specific skin benefits?<br/>Tell us what you're looking for:",
        options: [
          {
            label: "Protective maintenance",
            value: "Protective maintenance",
          },
          {
            label: "Dryness/Dehydration",
            value: "Dryness/Dehydration",
          },
          {
            label: "Dullness or lacklustre tone",
            value: "Dullness or lacklustre tone",
          },
          {
            label: "General signs of ageing",
            value: "General signs of ageing",
          },
          {
            label: "Signs of congestion",
            value: "Signs of Congestion",
          },
        ],
      },
      {
        question: "Could you describe your skin type?",
        options: [
          { label: "Sensitive", value: "Sensitive" },
          { label: "Reactive", value: "Reactive" },
          { label: "Dry", value: "Dry" },
          { label: "Combination to oily", value: "Combination to oily" },
        ],
      },
      {
        question: "Any concerns with the eye area we should know about?",
        options: [
          { label: "Dark circles", value: "Dark circles" },
          {
            label: "Fine lines and wrinkles",
            value: "Fine lines and wrinkles",
          },
          { label: "Under eye puffiness", value: "Under eye puffiness" },
          { label: "All of them", value: "All of them" },
          { label: "None of them", value: "None of them" },
        ],
      },
    ],
  };

  const { control, handleSubmit } = useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [swipeDirection, setSwipeDirection] = useState(null);

  const onSubmit = (data) => {
    setShowPostSurveyView(true);
    localStorage.setItem(
      "prompt",
      `question: How does your skin feel during the day? answer:${answers[0].selectedOption}; 
        question: What is your skin goal? answer: ${answers[1].selectedOption}; 
        question: How do you like your skincare to feel? answer: ${answers[2].selectedOption}`
    );
  };

  const handleOptionSelect = (selectedOption) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentStep] = { selectedOption };
      return newAnswers;
    });
  };

  const handleNext = () => {
    if (answers[currentStep]?.selectedOption) {
      setSwipeDirection("left");
      setTimeout(() => {
        setSwipeDirection("right");
        setCurrentStep((prevStep) =>
          prevStep === initialFormData.steps.length - 1 ? 0 : prevStep + 1
        );
        setTimeout(() => {
          setSwipeDirection(null);
        }, 0);
      }, 500);
    }
  };

  const handlePrev = () => {
    setSwipeDirection("right");
    setTimeout(() => {
      setSwipeDirection("left");
      setCurrentStep((prevStep) =>
        prevStep === 0 ? initialFormData.steps.length - 1 : prevStep - 1
      );
      setTimeout(() => {
        setSwipeDirection(null);
      }, 0);
    }, 500);
  };

  const handleCancel = () => {
    setShowPostSurveyView(false);
  };

  return (
    <StyledContainer
      maxWidth="sm"
      style={{
        transform: `translateX(${
          swipeDirection === "left"
            ? "200%"
            : swipeDirection === "right"
            ? "-200%"
            : "0"
        })`,
        fontFamily: "'magnat'",
      }}
    >
      {showPostSurveyView ? (
        <PostSurveyView onCancel={handleCancel} onComplete={onComplete} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <QuestionContainer>
            <>
              <p
                style={{
                  fontSize: "22px",
                  marginBottom: 20,
                  fontFamily: "'magnat'",
                  fontWeight: 500,
                  textTransform: "none",
                  width: 400,
                }}
                dangerouslySetInnerHTML={{
                  __html: initialFormData.steps[currentStep].question,
                }}
              ></p>
              <FormControl component="fieldset">
                <RadioGroup
                  control={control}
                  name={`steps[${currentStep}].selectedOption`}
                  value={answers[currentStep]?.selectedOption || ""}
                  onChange={(e) => handleOptionSelect(e.target.value)}
                >
                  {initialFormData.steps[currentStep].options.map(
                    (option, optionIndex) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={
                          <OptionButton
                            selected={
                              option.value ===
                              answers[currentStep]?.selectedOption
                            }
                            onClick={() => handleOptionSelect(option.value)}
                          >
                            {option.label}
                          </OptionButton>
                        }
                        label={null}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
              <ButtonsContainer>
                <NextButton
                  variant="outlined"
                  onClick={handlePrev}
                  disabled={currentStep === 0}
                  sx={{
                    color: "black",
                    "&:hover": {
                      color: primaryColor,
                      border: `1px solid ${primaryColor}`,
                      "&.Mui-disabled": {
                        color: primaryColor,
                      },
                    },
                  }}
                >
                  Back
                </NextButton>

                {currentStep < initialFormData.steps.length - 1 && (
                  <NextButton
                    variant="contained"
                    sx={{
                      backgroundColor: primaryColor,
                      "&:hover": {
                        backgroundColor: primaryColor,
                      },
                      color: "white",
                      "&.Mui-disabled": {
                        background: "#b4b8a3",
                        color: "#9E9595 ",
                      },
                      border: "none",
                      fontFamily: "'magnat'",
                    }}
                    onClick={handleNext}
                    disabled={!answers[currentStep]?.selectedOption}
                  >
                    Next
                  </NextButton>
                )}
                {currentStep === initialFormData.steps.length - 1 && (
                  <NextButton
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: primaryColor,
                      "&:hover": {
                        backgroundColor: primaryColor,
                      },
                      color: "white",
                      "&.Mui-disabled": {
                        background: "#b4b8a3",
                        color: "#9E9595",
                      },
                      border: "none",
                      fontFamily: "'magnat'",
                    }}
                    disabled={!answers[currentStep]?.selectedOption}
                  >
                    Continue
                  </NextButton>
                )}
              </ButtonsContainer>
            </>
          </QuestionContainer>
        </form>
      )}
    </StyledContainer>
  );
};

export default Survey;
