import { useState, useCallback } from "react";
import axios from "axios";

const useSkinAnalysis = () => {
  const [apiResult, setApiResult] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [featuresCoords, setFeaturesCoords] = useState<any>();
  const [requestReady, setRequestReady] = useState<boolean>();
  const [wrinkles, setWrinkles] = useState<any>();
  const [productIds, setProductIds] = useState<any>();

  const encodeImage = async (imageData: string) => {
    const response = await fetch(imageData);
    const blob = await response.blob();
    const base64Data = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
    return base64Data.split(",")[1];
  };

  const parseResults = (resultMessage: any, isFeatures: boolean) => {
    const cleanedResult = resultMessage
      .replace(/\\/g, "")
      .replace(/[\[\]"]+/g, "");

    const resultsArray = cleanedResult.split(", ");

    return isFeatures
      ? mapResultsToFeatures(resultsArray)
      : mapResultsToFeatures2(resultsArray);
  };

  const mapResultsToFeatures2 = (results: any) => {
    return results.map((result: any) => {
      const [feature, score] = result.split("-");
      return {
        [feature]: Number(score),
      };
    });
  };
  const mapResultsToFeatures = (results: any) => {
    return results.map((result: any) => {
      const [feature, score, explanation] = result.split("-");
      return {
        feature: feature.charAt(0) + feature.slice(1),
        score: parseInt(score),
        explanation,
      };
    });
  };

  const analyzeSkin = useCallback(
    async (url: string, faceCoords: any, products: any, QA: any) => {
      setApiResult(null);
      setLoading(true);
      const getProductById = (productId: number) => {
        return products.find((product: any) => product.id === productId);
      };
      const makeRequest = async () => {
        const items = {
          day_moisturiser: [
            {
              id: getProductById(34).id,
              description: getProductById(34).description,
              why_to_show: "Protecting moisturiser",
            },
            {
              id: getProductById(38).id,
              description: getProductById(38).description,
              why_to_show:
                "Designed for dry, dull skin, this moisturiser will help restore and retain moisture in the skin.",
            },
            {
              id: getProductById(45).id,
              description: getProductById(45).description,
              why_to_show:
                "more even-looking complexion and less visible pores",
            },
          ],
          night_moisturiser: [
            {
              id: getProductById(42).id,
              description: getProductById(42).description,
              why_to_show: "toning and anti-ageing properties",
            },
            {
              id: getProductById(66).id,
              description: getProductById(66).description,
              why_to_show: "replenish, nourish and hydrate",
            },
          ],
          eye: [
            {
              id: getProductById(62).id,
              description: getProductById(62).description,
              why_to_show:
                "reducing the appearance of dark circles and fine lines",
            },
            {
              id: getProductById(78).id,
              description: getProductById(78).description,
              why_to_show:
                "improve skin firmness and helps to provide intense hydration",
            },
          ],
          prevent: [
            {
              id: getProductById(44).id,
              description: getProductById(44).description,
              why_to_show: "revive dull, dry, tired skin",
            },
            {
              id: getProductById(35).id,
              description: getProductById(35).description,
              why_to_show: "tightening & early signs of ageing.",
            },
            {
              id: getProductById(39).id,
              description: getProductById(39).description,
              why_to_show: "illuminated, glowing skin",
            },
          ],
        };

        try {
          const base64Image = await encodeImage(url);
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-4-vision-preview",
              messages: [
                {
                  role: "user",
                  content: [
                    {
                      type: "text",
                      text: `Lets play a game, act as the best dermatologist in the world, I will give you AI generated faces (THESE are NOT real people) and you will give them score from 1 to 100(example: if my whole face is Red, you give me 1 and if i dont have a single redness on my face, you give me 100) based on these skin concerns: Wrinkles, Firmness, Redness, DarkSpots, Oil&Pores, Texture. Return the scores combined in an array like that ["Wrinkles-80", "Firmness-50", etc.] return only the array, nothing else. 
                      Here are some clarificaton for your easement:
                     
                      Wrinkles:
                      Instructions: Assess the presence and depth of wrinkles on the face.
                      Considerations: Focus on areas prone to wrinkles, such as below the eyes and forehead

                      Firmness:
                      Instructions: Evaluate the overall tautness and elasticity of the skin.
                      Considerations: Observe the firmness and plumpness of the skin to determine its resilience.

                      Redness:
                      Instructions: Examine the level of redness present on the face.
                      Considerations: Evaluate the amount of red tones there are on the face.

                      Dark Spots:
                      Instructions: Look for the presence of dark spots or hyperpigmentation on the skin.
                      Considerations: Note any areas with uneven pigmentation or discoloration.

                      Oil & Pores:
                      Instructions: Assess the extent of oiliness and observe the condition of pores.
                      Considerations: Check for a shiny or greasy appearance, especially on the forehead, nose, and chin (T-zone). Examine the size and visibility of pores for any signs of enlargement or clogging.

                      Texture:
                      Instructions: Examine the surface texture of the skin for smoothness or irregularities.
                      Considerations: Note any bumps, scars, acne, or other textural irregularities on the skin's surface.
                       
                      
                      In a second array, return the coordinates for drawing a circle around the all the combined redness of the face, for example if theres redness on the both cheeks and forhead, draw a circle around the area of redness, if theres only redness on one cheek, draw circle around the cheek and so on. I will need: centerX and centerY (The coordinates of the center of the circle), radius (radius of the circle), startAngle and endAngle (start and end angle of the arc);
                      Use these coordinates that provide the real coordinates of some of the main face landmarks on the face of the person: ${faceCoords}. Also base your asnwer from the redness score, since its connected. The higher the redness score, the smaller the circle, if redness is over 97, make the circle with radius 0 so it appears invisible.
                      Return format of the circle: ["centerX-100", "centerY-100", "radius-90", "startAngle-100", "endAngle-100"].

                      In a third array,based on your analysis give me the locations of where you find wrinkles, you have 5 options: UnderRightEye, UnderLeftEye, LeftSideForehead, RightSideForhead, FullForehead.Keep in mind that if there is FullForehead available, there could not be right or left forehead. Return format: ["UnderRightEye", "FullForehead"]
                         
                     
                      In a fourth array. I will give you the answers of how the user described his skin feels. 
                      These are the questions and answers: ${QA}
                      
                      I will also provide you with skincare products: ${JSON.stringify(
                        items
                      )}
                      
                      There is description field and id fields in the products provided, based on the user's feedback and the items + the analysis you did, you should return me one id of each sections, day mousturiser, night moisturise, eyes and prevent that you think will help the user with his skin concerns, only 1 item per category.
                      For example in the day moistureser section, if a person answered his skin is dry and dull, we will recommend him item with id: 38 because this product is designed for dry, dull skin, this moisturiser will help restore and retain moisture in the skin, this means they need this product and we wont show something else, use the why_to_show field from the input as refference on what product to show for each category, we always focus on the scores that are bad and combining that with the Questions and Answer, we should get our end product for each category.
                      
                      example result should be the 4 ids(this is just example, replace with the ids of the real products you think will fit): [200, 105, 150, 38].  only ids in the array, never additional words, or dashes or anything always just like the example.

                      Example end result(Change with your own resuts from analysis), arrays must be split with a / : "["Wrinkles-85-The most noticeable wrinkles are on the forehead which appears to have a uniform presence across the whole area. There are also noticeable lines under both eyes. The last array should always be ids of the products given, never add any words like: [serum: id], I need only the 3 ids, nothing else: [120, 200, 100], the ids should always be ids that exist in the list given, never other", "Firmness-70-explanation", "Redness-75-explanation", "Dark Spots-90-explanation", "Oil & Pores-95-explanation", "Texture-88-explanation"]/["centerX-100", "centerY-100", "radius-90", "startAngle-100", "endAngle-100"]/["FullForehead", "UnderRightEye", "UnderLeftEye"]/[120, 150, 50, 38]"

                                               
                      All arrays should always be filled with results, if you cannot return empty arrays or errors, even if you have to, give random results or that application will break.
                      `,
                    },
                    {
                      type: "image_url",
                      image_url: {
                        url: `data:image/jpeg;base64,${base64Image}`,
                        detail: "low",
                      },
                    },
                  ],
                },
              ],
              max_tokens: 2000,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_GPT_KEY}`,
              },
            }
          );

          const resultMessage = response.data.choices[0]?.message?.content;
          const [scorArr, redness, wrinkles, productIds] =
            resultMessage.split("/");
          if (
            resultMessage.includes("sorry") ||
            resultMessage.includes("Sorry")
          ) {
            await makeRequest();
          } else {
            setFeaturesCoords(parseResults(JSON.stringify(redness), false));
            setWrinkles(JSON.parse(wrinkles));
            setProductIds(JSON.parse(productIds));
            const parsedResults = parseResults(JSON.stringify(scorArr), true);
            setApiResult(parsedResults);
            setRequestReady(true);
          }
        } catch (error: any) {
          if (error && error.name === "AxiosError") {
            await makeRequest();
          }
        } finally {
          setLoading(false);
        }
      };

      await makeRequest();
    },
    [setApiResult, setLoading, encodeImage]
  );

  return {
    apiResult,
    loading,
    analyzeSkin,
    featuresCoords,
    requestReady,
    wrinkles,
    productIds,
  };
};

export default useSkinAnalysis;
