import { Avatar, Container, Typography, styled } from "@mui/material";
import React from "react";

interface ITotalScore {
  scores: Array<any>;
}

const AvatarContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TransparentBackgroundAvatar = styled(Avatar)`
  background: transparent;
  border: 2px solid ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the main number vertically */
`;

const MainNumber = styled("div")`
  font-size: 26px;
  color: black;
`;

const SecondaryNumber = styled("div")`
  font-size: 11px;
  margin-top: 2px;
  color: black;
`;

const StyledTypography = styled(Typography)({
  fontSize: 10,
  fontFamily: "'magnat'",
});

const TotalScore: React.FC<ITotalScore> = ({ scores }) => {
  const total =
    Number(scores[0].score) +
    Number(scores[1].score) +
    Number(scores[2].score) +
    Number(scores[3].score) +
    Number(scores[4].score) +
    Number(scores[5].score);

  const average = Math.ceil(total / 6);

  return (
    <Container
      sx={{
        marginTop: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
      }}
    >
      <AvatarContainer>
        <Typography sx={{ fontWeight: "bold" }}>Skin Score</Typography>
        <TransparentBackgroundAvatar
          sx={{
            border: `4px solid #a2c4c9`,
            borderStyle: "dashed",
            width: 56,
            height: 56,
          }}
        >
          <>
            <MainNumber>{average}</MainNumber>
            <SecondaryNumber>/100</SecondaryNumber>
          </>
        </TransparentBackgroundAvatar>
        <div
          style={{
            color: "black",
            textDecoration: "underline",
            fontSize: "11px",
            marginTop: 3,
          }}
        ></div>
      </AvatarContainer>
      <div>
        <StyledTypography>
          Using advanced AI technology, we have
        </StyledTypography>
        <StyledTypography>
          scanned your skin and assigned it an overall
        </StyledTypography>
        <StyledTypography>
          health score - the higher your score, the
        </StyledTypography>
        <StyledTypography>
          healthier your skin. From 1 to 100 we have
        </StyledTypography>
        <StyledTypography>
          assigned a score to each skin concern.
        </StyledTypography>
      </div>
    </Container>
  );
};

export default TotalScore;
