import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Link,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface IProductItem {
  product: any;
}

const ProductCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: "16px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#EEEDE8",
  boxShadow: "none",
}));

const ProductImage = ({
  src,
  height,
  width,
}: {
  src: string;
  height: string;
  width: string;
}) => (
  <img
    alt="Product"
    src={src}
    style={{
      width: width,
      height: height,
      marginRight: "14px",
      objectFit: "cover",
      marginLeft: "15px",
    }}
  />
);

const primaryColor = "#B7BCA1";

const ProductInfo = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const ProductItem: React.FC<IProductItem> = ({ product }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const descriptionLengthThreshold = isSmallScreen ? 100 : 150; // Adjust as needed
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  console.log(product);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        backgroundColor: "#EEEDE8",
        paddingTop: 50,
        paddingBottom: 25,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          paddingBottom: isSmallScreen ? 6 : 7,
          fontFamily: "'magnat'",
          fontWeight: "bold",
        }}
      >
        {product.category}
      </Typography>
      <ProductCard>
        <ProductImage
          height="200px"
          width={isSmallScreen ? "200px" : "250px"}
          src={product.product.image_link.replace(">", "").trim()}
        />
        <ProductInfo sx={{ marginLeft: isSmallScreen ? 0 : 5 }}>
          <Typography
            variant={isSmallScreen ? "body1" : "h6"}
            component="div"
            sx={{
              fontFamily: "'magnat'",
              width: `${isSmallScreen ? 99 : 240}px`,
              marginBottom: 2.5,
            }}
          >
            {product.product.title === 24
              ? "24k Gold Tightening Jelly Mask"
              : product.product.title.replace(">", "").trim()}
          </Typography>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              fontFamily: "'magnat'",
            }}
          >
            £
            {`${
              product.product.price.toString().length === 2 ||
              product.product.price.toString().length === 3 ||
              product.product.price.toString().length === 1
                ? product.product.price + ".00"
                : product.product.price
            }`}
          </Typography>
          <Link
            href={product.product.link}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isSmallScreen ? 100 : 160,
              lineHeight: 3,
              borderRadius: 20,
              textDecoration: "none",
              fontSize: isSmallScreen ? 14 : 17,
              marginTop: 2.5,
              backgroundColor: primaryColor,
              "&:hover": {
                backgroundColor: primaryColor,
              },
              color: "white",
              "&.Mui-disabled": {
                background: "#b4b8a3",
                color: "#9E9595",
              },
              border: "none",
              fontFamily: "'magnat'",
            }}
          >
            Discover now
          </Link>
        </ProductInfo>
      </ProductCard>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "'magnat'",
          marginTop: 2,
          overflow: "hidden",
          maxHeight: showFullDescription ? "none" : "72px",
          width: isSmallScreen ? 300 : 550,
          textAlign: "left",
        }}
      >
        {product.product.description.length > descriptionLengthThreshold
          ? showFullDescription
            ? product.product.description
            : product.product.description.slice(0, descriptionLengthThreshold) +
              "..."
          : product.product.description}
      </Typography>
      {product.product.description.length > descriptionLengthThreshold && (
        <div
          style={{
            marginTop: 1,
            display: "flex",
            justifyContent: "start",
            alignItems: "left",
            width: isSmallScreen ? 315 : 565,
          }}
        >
          {showFullDescription ? (
            <Button
              onClick={toggleDescription}
              sx={{
                textTransform: "none",
                color: "black",
                fontFamily: "'magnat'",
                fontSize: "1rem",
                textDecoration: "underline",
              }}
            >
              Read Less{" "}
              <FontAwesomeIcon
                icon={faChevronUp}
                style={{
                  fontSize: "0.7rem",
                  marginTop: "5px",
                  marginLeft: "7px",
                }}
              />
            </Button>
          ) : (
            <Button
              onClick={toggleDescription}
              sx={{
                textTransform: "none",
                color: "black",
                fontFamily: "'magnat'",
                fontSize: "1rem",
                textDecoration: "underline",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Read More{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                  fontSize: "0.7rem",
                  marginTop: "5px",
                  marginLeft: "7px",
                }}
              />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductItem;
