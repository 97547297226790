// Header.tsx
import { styled } from "@mui/material";
import React from "react";

const StyledHeader = styled("div")({
  textAlign: "center",
  marginTop: "10px",
  position: "relative",
});

const Logo = styled("img")({
  width: "100px",
  zIndex: 1,
  marginTop: 10,
});

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Logo
        src="https://flanerie-skincare.com/img/flanerie-logo-1649686248.jpg"
        alt="logo"
      />
    </StyledHeader>
  );
};

export default Header;
