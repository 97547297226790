import React from "react";
import Webcam, { WebcamProps } from "react-webcam";

interface IWebcamCapture {
  webcamRef: React.Ref<Webcam>;
  isCameraVisible: boolean;
  handleUserMedia: () => void;
}

const videoConstraints = {
  width: 660,
  height: 370,
  facingMode: "user",
  zoom: 1.1,
};

const WebcamCapture: React.FC<IWebcamCapture> = ({
  webcamRef,
  isCameraVisible,
  handleUserMedia,
}) => {
  return isCameraVisible ? (
    <Webcam
      audio={false}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      mirrored
      style={{ display: "block", margin: "auto", marginTop: "20px" }}
      onUserMedia={handleUserMedia}
      videoConstraints={videoConstraints as WebcamProps["videoConstraints"]}
    />
  ) : null;
};

export default WebcamCapture;
